import { Vue } from 'vue-class-component';
import { App } from 'vue';
import axios from 'axios';

export const injectAxios = (app: App): void => {
  axios.defaults.baseURL = `${process.env.VUE_APP_DOMAIN_API}`;
  Vue.prototype.$axios = axios;
  app.config.globalProperties.$axios = axios;

  app.provide('axios', axios);
};
