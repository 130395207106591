import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import LocationPage from '../views/LocationPage.vue';
import OrderCompletePage from '../views/OrderCompletePage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/kennzeichen/:code/:office',
    name: 'Location',
    component: LocationPage,
  },
  {
    path: '/bestellabschluss',
    name: 'OrderCompletePage',
    component: OrderCompletePage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
