import { createApp } from 'vue';
import App from './App.vue';

import { injectAxios } from './inject/axios';
import { injectRouter } from './inject/router';
import { injectStore } from './inject/store';

import 'bootstrap';
import * as validators from '@vuelidate/validators';
import i18n from './i18n';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
import Vue3Lottie from 'vue3-lottie';

/* import specific icons */
import {
  faCircleXmark,
  faCircleCheck,
  faSquare,
  faSquareCheck,
} from '@fortawesome/free-regular-svg-icons';
import {
  faCircleQuestion,
  faArrowRight,
  faMagnifyingGlass,
  faCircleInfo,
  faCartShopping,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* add icons to the library */
library.add(faCircleXmark);
library.add(faCircleCheck);
library.add(faCircleQuestion);
library.add(faArrowRight);
library.add(faMagnifyingGlass);
library.add(faCircleInfo);
library.add(faCartShopping);
library.add(faCheck);
library.add(faSquare);
library.add(faSquareCheck);

// or import { createI18nMessage } from '@vuelidate/validators'
const { createI18nMessage } = validators;

// extract the `t` helper, should work for both Vue 2 and Vue 3 versions of vue-i18n
const { t } = i18n.global || i18n;

// pass `t` and create your i18n message instance
const withI18nMessage = createI18nMessage({ t });

// wrap each validator.
export const required = withI18nMessage(validators.required);
// validators that expect a parameter should have `{ withArguments: true }` passed as a second parameter, to annotate they should be wrapped
export const minLength = withI18nMessage(validators.minLength, { withArguments: true });
// or you can provide the param at definition, statically
export const maxLength = withI18nMessage(validators.maxLength(10));

const app = createApp(App).use(i18n);

app.use(Vue3Lottie);

// inject plugins
injectStore(app);
injectRouter(app);
injectAxios(app);
app.component('font-awesome-icon', FontAwesomeIcon);
// mount app
app.mount('#app');
