import { InjectionKey } from 'vue';
import { createStore, Store } from 'vuex';
// import mobuldes
// import interfaces
import { IRootState, IStoreModuleContext } from '../classes/interfaces/store';
// import queries

// define injection key
export const key: InjectionKey<Store<IRootState>> = Symbol();

export const store = createStore<IRootState>({
  state: {
    loaded: false,
  } as any,
  mutations: {
    setLoaded(state: IRootState, value: boolean) {
      state.loaded = value;
    },
  },
  actions: {
    async loadData(context: IStoreModuleContext<IRootState>) {
      context.commit('setLoaded', true);
      return true;
    },
  },
  modules: {},
});
