<template>
  <div class="info-box">
    <div class="icon">
      <font-awesome-icon :icon="['fas', 'circle-info']" />
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss">
@import '../assets/scss/_vars';

.info-box {
  background-color: #eee;
  border-radius: 6px;
  padding: 16px;
  display: flex;
  gap: 16px;
  .icon {
    font-size: 30px;
    color: $primary;
    flex-shrink: 0;
  }
}
</style>
