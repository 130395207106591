export interface ILocationCode {
  Code: string;
}
export interface IReservationResponse {
  code: number;
  success: boolean;
}
export interface IAvailabilityResponse {
  code: number;
  alternatives: string[];
}
export interface IInputRestriction {
  RestrictPLZAndPlace: boolean;
  PlzAndPlace: { PlzAndPlace: string }[];
}

export interface ILicenseOffice {
  id: string;
  URL: string;
  Name: string;
  Slug: string;
  Information: string;
  LocationCodes: ILocationCode[];
  input_restriction: IInputRestriction | null;
  HeaderImage: { url: string } | null;
}

export interface ISearchResult {
  Code1: string;
  Code2: string;
  Code3: string;
  Available: boolean;
  Loading: boolean;
  Date: string;
  CarType: ECarType;
  PlateType: EPlateType;
  IsSeason: boolean;
  SeasonStart: TMonth | null;
  SeasonEnd: TMonth | null;
}

export interface IUserData {
  Title: string;
  Firstname: string;
  Lastname: string;
  Street: string;
  Housenumber: string;
  PLZ: string;
  Place: string;
  Email: string;
  Phone: string;
  Birthdate: string;
}

export interface IOrderData {
  code1: string;
  code2: string;
  code3: string; // success: 2412, error: 1234
  carType: ECarType;
  plateType: EPlateType;
  isSeason: boolean;
  seasonStart: TMonth | null;
  seasonEnd: TMonth | null;
  licensing_office: number; // change for dev env
  executeReservation: boolean;
  customerData: {
    title: string;
    firstname: string;
    lastname: string;
    mail: string;
    streetWithNumber: string;
    plz: string;
    place: string;
    phone: string;
    countryCode: string;
    paypalPayerId: string;
  };
}

export enum EPlateType {
  Default = 'Default',
  OldTimer = 'OldTimer',
  Electric = 'Electric',
}

export enum ECarType {
  PKW = 'PKW',
  Trailer = 'Trailer',
  MotorCycle = 'MotorCycle',
}

type TCarTypeMap = Record<ECarType, string>;
export const CarTypeMap: TCarTypeMap = {
  [ECarType.PKW]: 'PKW',
  [ECarType.Trailer]: 'Anhänger',
  [ECarType.MotorCycle]: 'Motorrad',
};

export type TMonth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type TMonthMap = Record<TMonth, string>;
export const MonthNameMap: TMonthMap = {
  1: 'Januar',
  2: 'Februar',
  3: 'März',
  4: 'April',
  5: 'Mai',
  6: 'Juni',
  7: 'Juli',
  8: 'August',
  9: 'September',
  10: 'Oktober',
  11: 'November',
  12: 'Dezember',
};

export const MonthShortMap: TMonthMap = {
  1: '01',
  2: '02',
  3: '03',
  4: '04',
  5: '05',
  6: '06',
  7: '07',
  8: '08',
  9: '09',
  10: '10',
  11: '11',
  12: '12',
};
