<template>
    <div class="row">
        <div class="col">
          <p>
            Sie möchten ein ganz bestimmtes Nummernschild oder Ihr bestehendes Kennzeichen durch ein
            Wunschkennzeichen ersetzen? Dann sind Sie bei uns genau richtig. als Dienstleister für
            Auto-Zulassungen bieten wir:
          </p>
          <p>
            <ul>
              <li>Die Möglichkeit, Ihr KFZ-Kennzeichen online zu reservieren</li>
              <li>Unschlagbare Preise im Vergleich zu örtlichen Schildermachern</li>
              <li>Auf Wunsch deutschlandweite Zulassungsfahrten</li>
              <li>jeglisches Zubehör für Ihre Nummernschilder</li>
              <li>Hohe Qualitätsstandards</li>
            </ul>
            Sparen Sie sich den doppelten Gang zur Zulassungsstelle! In unserem Onlineshop können
            sie neben der Reservierung Ihres Nummernschildes gleich die passenden Autokennzeichen
            sowie weiteres Zubehör deutschlandweit bestellen.<br />
          </p>
          <p class="fw-bold mb-0">Wunschkennzeichen Prüfen, Reservieren und Bestellen</p>
          <p>
            Geben Sie bei Ihrer Bestellung einfach Ihren Zulassungsbezirk an und wir leiten Sie
            direkt zur Online-Reservierung Ihres zuständigen Straßenverkehrsamtes weiter. dort wird
            die Verfügbarkeit Ihres Kennzeichens überprüft. Ist Ihre Kennzeichenkombination frei,
            reservieren Sie die gewünschte Buchstaben- sowie Ziffernfolge und bestellen anschließend
            Ihre Kennzeichen direkt online.<br />
            Falls eine Reservierung in Ihrer Zulassungsbehörde nur telefonisch möglich ist oder Sie
            anderweitig Fragen haben, stehen wir Ihnen gerne tatkräftig zur Seite
          </p>
          <br />

          <p class="fw-bold mb-0">Kompetenter Service zu Ihrer Zufriedenheit</p>
          <p>
            Wir legen großen Wert auf Ihre Zufriedenheit. Zögern Sie nicht, bei Fragen oder Anliegen
            rund um Kennzeichen, Zulassungen sowie Reservierungen Ihres Wunschkennzeichens auf uns
            zu zukommen.<br />
            Wir bearbeiten alle Anfragen und Bestellungen zeitnah und stehen Ihnen gerne mit unserem
            Kontaktformular oder unserem telefonischen Kundenservice zur Verfügung. Denn SERVICE
            wird bei uns seit 1974 großgeschrieben!
          </p>
          <br />
          <p class="fw-bold mb-0">Hohe Sicherheit und Zertifizierte Qualität</p>
          <p>
            Neben einer umfangreichen Auswahl an verschiedenen Zubehör profitieren Fahrzeughalter
            beim Kauf eines personalisierten Wunschkennzeichens von weiteren zahlreichen Vorteilen:
          </p>
          <br />
          <ul>
            <li>Qualitativ hochwertige Prägung der Nummernschilder</li>
            <li>Von der <strong>DIN Cetro</strong> zertifiziert</li>
            <li>In Deutschland hergestellt</li>
            <li>Einfach zu montieren</li>
            <li>komfortable Lieferungs- und Zahlungsbedingungen!</li>
          </ul>
          <p>
            Alle Bestellungen, die montags bis freitags während unserer Versandzeiten eingehen,
            werden noch am <strong>selben Tag kostenlos verschickt</strong>. Zudem ist die Sendung versichert und
            einfach per Sendungsverfolgung nachvollziehbar. Dadurch können wir Ihnen eine schnelle,
            sichere und reibungslose Lieferung Ihrer Wunschkennzeichen gewährleisten.
          </p>
        </div>
      </div>
</template>